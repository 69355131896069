.custom-scrollbar-lkm::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar-lkm {
  /* Set overflow to auto to enable scrolling */
  /* overflow: auto; */
  /* Hide default scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}
